import React, { useEffect, useCallback } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { getToolsAction, getToolsTypesAction } from '../../actions/toolsActions';
import Card from '../../components/Card/Card';
import User from '../../components/User/User';
import { ApplicationState } from '../../types';

import logo from '../../assets/images/logo-octasmart.svg';
import styles from './DoctorList.module.scss';

function OctaList(): JSX.Element {
  const {
    toolsList, lists,
  } = useSelector((state : ApplicationState) => state.toolsReducer);
  const { user, notifsIsActive } = useSelector((state : ApplicationState) => state.authReducer);

  const dispatch = useDispatch();

  const getData = useCallback(() => {
    getToolsAction(dispatch);
    getToolsTypesAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={`${styles.container} ${notifsIsActive ? styles['is-notifs'] : ''}`}>
      <header>
        <div className={styles.logo}>
          <img src={logo} alt="logo" />
        </div>
        <User />
      </header>
      {user?.firstName && (
        <>
          <h1>
            Bonjour
            {` ${user?.firstName}`}
          </h1>
          <h2>Mes outils</h2>
          <div className={styles.list}>
            {toolsList?.filter((t) => user.tools.find((ut) => ut.tool === t._id))?.map((t) => (
              <Card
                key={t._id}
                tool={t}
                type={lists.types?.find((type) => type.value === t.type)?.label}
              />
            ))}
          </div>
          <h2>Découvrir l’offre des services Octapharma</h2>
          <div className={styles.list}>
            {toolsList?.filter((t) => !user.tools.find((ut) => ut.tool === t._id))?.map((t) => (
              <Card
                key={t._id}
                tool={t}
                type={lists.types?.find((type) => type.value === t.type)?.label}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
}

export default OctaList;
