import React from 'react';
import styles from './error-field.module.scss';

interface IErrorField {
  message?: string,
}

function ErrorField({ message = '' }: IErrorField): JSX.Element {
  return (
    <div className={styles['errors-fields']}>
      <p className={styles.message}>{message}</p>
    </div>
  );
}

export default ErrorField;
