import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillStar } from 'react-icons/ai';

import { API_URL } from '../../constants';
import { ApplicationState } from '../../types';
import { ITool } from '../../types/tools.types';

import login from '../../assets/images/login.png';
import styles from './OctaTool.module.scss';
import { putFavoritesAction } from '../../actions/authActions';

function DoctorTool({ tool } : { tool: ITool }): JSX.Element {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { userUrl, user } = useSelector((state : ApplicationState) => state.authReducer);
  const isFavorite = user?.favoriteTools?.find((t) => t === tool._id);

  function handleChangeFavorite(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    let favoriteTools : string[] = user?.favoriteTools || [];
    if (isFavorite) {
      favoriteTools = favoriteTools.filter((f) => f !== isFavorite);
    } else {
      favoriteTools.push(tool._id);
    }
    if (user?._id) {
      putFavoritesAction(dispatch, { favoriteTools });
    }
  }

  function redirectToTool(e: React.MouseEvent<HTMLElement>) {
    e.preventDefault();
    const isActive = user?.tools?.find((t) => t.tool === tool._id);
    if (isActive && tool.url) {
      return window.open(tool.url, '_blank');
    }
    return navigate(`${userUrl}/${tool._id}`);
  }

  return (
    <div className={styles.container}>
      <button
        type="button"
        onClick={(e) => redirectToTool(e)}
        className={styles.link}
      >
        <div className={styles.logo}>
          {tool?.icon?.path
            ? <img src={`${API_URL}/${tool?.icon?.path}`} alt="tool" />
            : <img src={login} alt="tool" />}
        </div>
        <div className={styles.infos}>
          <h3>{tool?.title}</h3>
          <p>{tool?.description}</p>
        </div>
      </button>
      <div className={styles.star}>
        <button
          type="button"
          onClick={(e) => handleChangeFavorite(e)}
          className={isFavorite ? styles['is-favorite'] : ''}
        >
          <AiFillStar />
        </button>
      </div>
    </div>
  );
}

export default DoctorTool;
