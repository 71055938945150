import React, { useCallback, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { ErrorField, InputPassword } from '../../lib/HooksFormFields';

import { IResetPassword } from '../../types/auth.types';
import { ApplicationState } from '../../types';
import { AUTH_RESET_ERROR } from '../../actions/actions';
import { resetPassword } from '../../actions/authActions';

import logo from '../../assets/images/logo-octasmart.svg';
import login from '../../assets/images/login.png';
import loginMobile from '../../assets/images/login-mobile.png';
import styles from './Auth.module.scss';

type FormValues = {
  password: string,
};

function Reset(): JSX.Element {
  const { error, message } = useSelector((state : ApplicationState) => state.authReducer);
  const dispatch = useDispatch();
  const { token } = useParams();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const resetError = useCallback(() => {
    dispatch({ type: AUTH_RESET_ERROR });
  }, [dispatch]);

  function submitFrogot(values : FormValues) {
    const data: IResetPassword = {
      ...values,
      token: token || '',
    };
    return resetPassword(dispatch, data);
  }

  useEffect(() => {
    resetError();
  }, [resetError]);

  return (
    <div className={styles.container}>
      <div className={`${styles.col} ${styles.form}`}>
        <img src={logo} alt="logo" />
        <p className={`text-primary ${styles.mail}`}>
          Modifier votre mot de passe
        </p>
        <form
          className={styles.signin}
          onSubmit={handleSubmit(submitFrogot)}
        >
          <div className={styles['form-group']}>
            <InputPassword
              name="password"
              control={control}
              required
              requiredMessage="Veuillez saisir votre mot de passe"
              label="Mot de passe"
              placeholder="Saissisez votre mot de passe..."
            />
            {errors.password && <ErrorField message={errors.password.message} />}
          </div>

          <button
            type="submit"
            className={`${styles.submit} ${styles.password}`}
          >
            <p>Envoyer</p>
          </button>

          {error && <p className={styles.error}>{error}</p>}
          {message && <p className={styles.message}>{message}</p>}

          <Link to="/" className={styles.forgot}>Retour à la connexion</Link>

        </form>
        <div className={`${styles['illu-mobile']}`}>
          <img src={loginMobile} alt="illu-login" />
        </div>
        <p className={styles.legal}>
          Les informations recueillies font l’objet d’un traitement
          informatique chez OCTAPHARMA France.
          Le responsable de ces données est Octapharma France.
          Ces informations seront conservées pendant
          une durée de 3 ans à compter du dernier contact.
          A l’issue de ce délai, elles seront rendues anonymes et conservées
          à des fins de statistique et d’amélioration du service.
          Conformément à la réglementation sur la protection des données personnelles,
          vous avez un droit d’accès, de rectification,
          d’opposition, d’effacement et de limitation du traitement des données vous concernant,
          pour exercer ces droits,
          vous devez adresser votre demande à
          <a href="mailto:FR2DPO@octapharma.com" className="bold"> FR2DPO@octapharma.com</a>
        </p>
      </div>
      <div className={`${styles.col} ${styles.illu}`}>
        <img src={login} alt="illu-login" />
      </div>
    </div>
  );
}

export default Reset;
