import React, { useCallback, useEffect } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { MdPlayArrow } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import DOMPurify from 'dompurify';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { API_URL } from '../../constants';
import { getToolAction } from '../../actions/toolsActions';
import { logout } from '../../actions/authActions';

import { ApplicationState } from '../../types';

import User from '../../components/User/User';

import login from '../../assets/images/login.png';
import appStore from '../../assets/images/app-apple.png';
import file from '../../assets/images/pdf.svg';
import video from '../../assets/images/video.svg';
import styles from './Tool.module.scss';

function Tool(): JSX.Element {
  const { id } = useParams();
  const navigate = useNavigate();
  const { notifsIsActive } = useSelector((state : ApplicationState) => state.authReducer);
  const { tool, error } = useSelector((state : ApplicationState) => state.toolsReducer);

  const dispatch = useDispatch();

  const getData = useCallback(() => {
    getToolAction(dispatch, id);
  }, [dispatch, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (error === '401') {
      logout(dispatch);
      navigate('/');
    }
  }, [error]);

  return (
    <div className={`${styles.container} ${notifsIsActive ? styles['is-notifs'] : ''}`}>
      {tool?._id && (
        <>
          <div className={styles.col}>
            <header>
              <button
                type="button"
                onClick={() => navigate(-1)}
                className={styles.back}
              >
                <HiArrowLeft />
                Retour
              </button>
              <User />
            </header>

            <div className={`${styles.illu} ${styles.mobile}`}>
              {tool?.image
                ? <img src={`${API_URL}/${tool?.image?.path}`} alt="tool" />
                : <img src={login} alt="tool" />}
            </div>

            <div className={styles.infos}>
              <div className={styles.logo}>
                {tool?.icon
                  ? <img src={`${API_URL}/${tool?.icon?.path}`} alt="tool" />
                  : <img src={login} alt="tool" />}
              </div>
              <div className={styles.title}>
                <h1>{tool?.title}</h1>
                <p>{tool?.description}</p>
                {tool.url && tool._id !== '61d70886924add6f9461432a' && (
                  <a
                    href={tool.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span>Voir l&apos;outil</span>
                  </a>
                )}
              </div>
            </div>

            {tool?.files.length > 0 || tool?.urlVideo ? (
              <div className={styles.box}>
                <div className={styles['file-list']}>
                  {tool.files.map((f) => (
                    <a
                      key={f._id}
                      href={`${API_URL}/${f.file.path}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className={styles.file}>
                        <img src={file} alt="alt" />
                        <p>{f.title}</p>
                      </div>
                    </a>
                  ))}
                  {tool?.urlVideo && (
                    <a
                      href={tool.urlVideo}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <div className={styles.file}>
                        <img src={video} alt="alt" />
                        <p>Vidéo de présentation</p>
                      </div>
                    </a>
                  )}
                </div>
              </div>
            ) : null }

            {tool?.inPractice && (
              <div className={styles.box}>
                <h1>En pratique</h1>
                <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tool.inPractice) }}
                  className={styles.text}
                />
              </div>
            )}

            {tool?.textStore && (
              <div className={styles.box}>
                <h1>
                  Télécharger l’application
                  <br />
                  <span className={styles.note}>(depuis votre mobile)</span>
                </h1>
                <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tool.textStore) }}
                  className={styles.text}
                />
                <div className={styles.links}>
                  {tool.urlAppStore && (
                    <a
                      href={tool.urlAppStore}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={appStore} alt="app-store-icon" />
                    </a>
                  )}
                </div>
              </div>
            )}

            {tool?.codeVeeva && <p className={styles.code}>{tool.codeVeeva}</p>}

          </div>
          <div className={styles.col}>
            <div className={styles.illu}>
              {tool?.image
                ? <img src={`${API_URL}/${tool?.image?.path}`} alt="tool" />
                : <img src={login} alt="tool" />}
            </div>

            {tool?.withContactForm && (
              <div className={`${styles.box} ${styles.contact}`}>
                <h2>Formulaire de demande de contact</h2>
                <div className={styles['contact-text']}>
                  {tool.textContactForm && (
                    <div
                      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tool.textContactForm) }}
                      className={styles.text}
                    />
                  )}
                  <Link to={`/tool-contact/${id}`}>
                    <button
                      className={styles.white}
                      type="button"
                    >
                      <p>ok</p>
                      <MdPlayArrow size={22} />
                    </button>
                  </Link>
                </div>
              </div>
            )}

            {tool?.goodToKnow && (
              <div className={`${styles.box} ${styles.good}`}>
                <h2>Bon à savoir</h2>
                <div
                  dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(tool.goodToKnow) }}
                  className={styles.text}
                />
              </div>
            )}

          </div>
        </>
      )}
    </div>
  );
}

export default Tool;
