import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProfileAction } from '../../actions/authActions';

import { ApplicationState } from '../../types';
import { AUTH_RESET_ERROR } from '../../actions/actions';

import styles from './Auth.module.scss';

export default function SwitchIndex() {
  const { error, userUrl } = useSelector((state : ApplicationState) => state.authReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const resetError = useCallback(() => {
    dispatch({ type: AUTH_RESET_ERROR });
  }, [dispatch]);

  const getProfile = useCallback(() => {
    getProfileAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    resetError();
    getProfile();
  }, [resetError, getProfile]);

  useEffect(() => {
    if (userUrl) {
      const url = userUrl === '/tool-octapharma' ? '/tool-octapharma?category=0' : '/tool-doctor?category=0';
      navigate(url);
    }
  }, [userUrl, navigate]);

  useEffect(() => {
    if (error === '401') {
      navigate('/login');
      resetError();
    }
  }, [error, resetError, navigate]);

  return (
    <div className={styles.container} />
  );
}
