import {
  combineReducers,
  createStore,
  Reducer,
  Store,
  compose,
} from '@reduxjs/toolkit';
import { ApplicationState, Action, DispatchType } from '../types';

import authReducer from './authReducer';
import toolsReducer from './toolsReducer';

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export const reducers: Reducer<ApplicationState> = combineReducers<ApplicationState>({
  authReducer,
  toolsReducer,
});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store: Store<ApplicationState, Action> & {
  dispatch: DispatchType
} = createStore(
  reducers,
  composeEnhancers(),
);
