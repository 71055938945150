import React from 'react';
import { Controller } from 'react-hook-form';
import styles from './CheckBox.module.scss';

interface InputCheckboxProps {
  label: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: any,
  defaultValue?: boolean;
}

function CheckBox({
  label,
  name = '',
  control,
  defaultValue = false,
}: InputCheckboxProps) {
  return (
    <div className={styles.container}>
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue || false}
        render={({ field }) => (
          <input
            type="checkbox"
            id={name}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            className={styles.checkbox}
          />
        )}
      />
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
    </div>
  );
}

export default CheckBox;
