import { Action } from '../types';
import {
  GET_TOOLS, GET_TOOL, GET_TOOLS_TYPES, POST_TOOL_FORM, TOOL_ERROR,
} from '../actions/actions';
import { ToolsState } from '../types/tools.types';

const initialState: ToolsState = {
  toolsList: [],
  tool: null,
  lists: {
    types: [],
  },
  error: null,
  message: null,
};

const toolsReducer = (
  state: ToolsState = initialState,
  action: Action = { type: '' },
): ToolsState => {
  let updatedState = { ...state };
  let error = action.payload;
  switch (action.type) {
    case GET_TOOLS:
      updatedState = {
        ...state,
        toolsList: action.payload.tools,
        tool: null,
        error: null,
      };
      break;
    case TOOL_ERROR:
      if (error.status === 401) {
        error = '401';
      }
      updatedState = {
        ...state,
        error,
      };
      break;
    case POST_TOOL_FORM:
      updatedState = {
        ...state,
        message: action.payload,
      };
      break;
    case GET_TOOL:
      updatedState = {
        ...state,
        tool: action.payload.tool,
      };
      break;
    case GET_TOOLS_TYPES:
      updatedState = {
        ...state,
        lists: { ...state.lists, types: action.payload.types },
      };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default toolsReducer;
