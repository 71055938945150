import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';

import { ErrorField, InputEmail } from '../../lib/HooksFormFields';

import { forgotPassword } from '../../actions/authActions';

import logo from '../../assets/images/logo-octasmart.svg';
import login from '../../assets/images/login.png';
import loginMobile from '../../assets/images/login-mobile.png';
import styles from './Auth.module.scss';
import { IForgotPassword } from '../../types/auth.types';
import { ApplicationState } from '../../types';
import { AUTH_RESET_ERROR } from '../../actions/actions';

function Forgot(): JSX.Element {
  const { error, message } = useSelector((state : ApplicationState) => state.authReducer);
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IForgotPassword>();

  const resetError = useCallback(() => {
    dispatch({ type: AUTH_RESET_ERROR });
  }, [dispatch]);

  function submitFrogot(values : IForgotPassword) {
    resetError();
    return forgotPassword(dispatch, values);
  }

  useEffect(() => {
    resetError();
  }, [resetError]);

  return (
    <div className={styles.container}>
      <div className={`${styles.col} ${styles.form}`}>
        <img src={logo} alt="logo" />
        <p className={`text-primary ${styles.mail}`}>
          Recevoir un email pour
          <br />
          le changement du mot de passe
        </p>
        <form
          className={styles.signin}
          onSubmit={handleSubmit(submitFrogot)}
        >
          <div className={styles['form-group']}>
            <InputEmail
              name="email"
              control={control}
              required
              label="Email"
              placeholder="Saissisez votre email..."
            />
            {errors.email && <ErrorField message={errors.email.message} />}
          </div>

          <button
            type="submit"
            className={`${styles.submit} ${styles.password}`}
          >
            <p>Envoyer</p>
          </button>

          {error && <p className={styles.error}>{error}</p>}
          {message && <p className={styles.message}>{message}</p>}

          <Link to="/" className={styles.forgot}>Retour à la connexion</Link>

        </form>
        <div className={`${styles['illu-mobile']}`}>
          <img src={loginMobile} alt="illu-login" />
        </div>
        <p className={styles.legal}>
          Les informations recueillies font l’objet d’un traitement
          informatique chez OCTAPHARMA France.
          Le responsable de ces données est Octapharma France.
          Ces informations seront conservées pendant
          une durée de 3 ans à compter du dernier contact.
          A l’issue de ce délai, elles seront rendues anonymes et conservées
          à des fins de statistique et d’amélioration du service.
          Conformément à la réglementation sur la protection des données personnelles,
          vous avez un droit d’accès, de rectification,
          d’opposition, d’effacement et de limitation du traitement des données vous concernant,
          pour exercer ces droits,
          vous devez adresser votre demande à
          <a href="mailto:FR2DPO@octapharma.com" className="bold"> FR2DPO@octapharma.com</a>
        </p>
      </div>
      <div className={`${styles.col} ${styles.illu}`}>
        <img src={login} alt="illu-login" />
      </div>
    </div>
  );
}

export default Forgot;
