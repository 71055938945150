import React from 'react';
import { format } from 'date-fns';

import { API_URL } from '../../constants';

import { INews } from '../../types/auth.types';

import login from '../../assets/images/login.png';
import styles from './New.module.scss';

function News(
  { news, newsToggle }
  :
  { news: INews, newsToggle:(arg: INews) => void },
): JSX.Element {
  return (
    <button
      type="button"
      className={styles.container}
      onClick={() => newsToggle(news)}
    >
      <div className={styles.logo}>
        {news?.image?.path
          ? <img src={`${API_URL}/${news?.image?.path}`} alt="news" />
          : <img src={login} alt="tool" />}
      </div>
      <div className={styles.infos}>
        <h3>{news?.title}</h3>
        <div className={styles.infos}>
          <p>{format(new Date(news.date), 'dd/MM/yyyy')}</p>
          <p>
            {`${news?.author?.profile?.firstName} ${news?.author?.profile?.lastName}`}
          </p>
        </div>
      </div>
    </button>
  );
}

export default News;
