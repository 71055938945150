import { Action } from '../types';
import {
  AUTH_SIGNIN,
  AUTH_GET_PROFILE,
  AUTH_LOGOUT,
  AUTH_LOADING,
  AUTH_RESET_ERROR,
  AUTH_ERROR,
  AUTH_MESSAGE,
  GET_NEWS,
  GET_NOTIFS,
  SET_NOTIFS_IS_ACTIVE,
  PUT_USER,
} from '../actions/actions';
import { AuthState } from '../types/auth.types';

const initialState: AuthState = {
  user: null,
  isLoading: false,
  error: null,
  message: null,
  userUrl: null,
  news: null,
  notifs: null,
  notifsIsActive: false,
};

const authReducer = (
  state: AuthState = initialState,
  action: Action = { type: '' },
): AuthState => {
  let updatedState = { ...state };
  let errorMessage;
  let message = null;
  switch (action.type) {
    case AUTH_SIGNIN:
      localStorage.setItem('token', action.payload.token);
      localStorage.setItem('id', action.payload.user._id);

      updatedState = {
        ...state,
        user: action.payload.user,
        isLoading: false,
        error: null,
      };
      break;
    case PUT_USER:
      updatedState = { ...state, user: { ...updatedState.user, ...action.payload } };
      break;
    case AUTH_GET_PROFILE:
      updatedState = {
        ...state,
        user: action.payload,
        userUrl: action.payload.type === 'Octapharma' ? '/tool-octapharma' : '/tool-doctor',
        isLoading: false,
        error: null,
      };
      break;
    case AUTH_LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('id');

      updatedState = { ...state, user: null, userUrl: null };
      break;
    case AUTH_MESSAGE:
      if (action.payload?.message === 'Please check your email for the link to reset your password.') {
        message = 'Veuillez vérifier votre courrier électronique pour le lien pour réinitialiser votre mot de passe.';
      }
      if (action.payload?.message === 'Password changed successfully. Please login with your new password.') {
        message = 'Le mot de passe a été changé avec succès. Veuillez vous connecter avec votre nouveau mot de passe.';
      }
      updatedState = { ...state, message };
      break;
    case AUTH_LOADING:
      updatedState = { ...state, isLoading: false };
      break;
    case AUTH_RESET_ERROR:
      updatedState = { ...state, error: null, message: null };
      break;
    case AUTH_ERROR:
      errorMessage = 'Vous n\'êtes pas autoriser à accéder à ce contenu';
      if (action.payload.config.url.includes('auth/login')) {
        errorMessage = 'Adresse e-mail et/ou mot de passe incorrects.';
      } else if (action.payload.config.url.includes('auth/forgot-password')) {
        errorMessage = 'Aucun compte n\'est associé à cet adresse e-mail.';
      } else if (action.payload.data.error === 'That email address is already in use.') {
        errorMessage = 'Un compte est déjà associé à cet adresse e-mail.';
      } else if (action.payload.data.error === 'Your token has expired. Please attempt to reset your password again.') {
        errorMessage = 'Le mot passe n\'a pas pu être changé, rendez-vous sur la page "mot de passe oublié" pour essayer à nouveau';
      } else if (action.payload?.status === 401) {
        errorMessage = '401';
      }
      updatedState = { ...state, error: errorMessage };
      break;
    case GET_NOTIFS:
      updatedState = { ...state, notifs: action.payload };
      break;
    case GET_NEWS:
      updatedState = { ...state, news: action.payload };
      break;
    case SET_NOTIFS_IS_ACTIVE:
      updatedState = { ...state, notifsIsActive: !state.notifsIsActive };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default authReducer;
