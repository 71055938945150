import React, {
  useEffect, useCallback, useRef, useState,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RiCloseFill } from 'react-icons/ri';
import { format } from 'date-fns';
import queryString from 'query-string';

import { getToolsAction, getToolsTypesAction } from '../../actions/toolsActions';
import getNewsAction from '../../actions/newsActions';

import { ApplicationState } from '../../types';
import { ICategory } from '../../types/tools.types';
import { INews } from '../../types/auth.types';

import User from '../../components/User/User';
import Category from '../../components/Category/Category';

import logo from '../../assets/images/logo-octasmart.svg';
import styles from './OctaList.module.scss';
import useWindowSize from '../../hooks/useWindowSize';
import { logout } from '../../actions/authActions';
import ModalStandalone from '../../lib/ModalStandalone';
import { API_URL } from '../../constants';

export type ModaleRef = {
  toggle: () => void;
};

function OctaList(): JSX.Element {
  const {
    user, news, notifsIsActive,
  } = useSelector((state : ApplicationState) => state.authReducer);
  const { toolsList, error } = useSelector((state : ApplicationState) => state.toolsReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const size = useWindowSize();
  const navRef = useRef<HTMLUListElement | null>(null);
  const modaleRef = useRef<ModaleRef | null>(null);

  const [activeForm, setActiveForm] = useState(queryString.parse(location.search).category);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [newsList, setNewsList] = useState<INews[]>([]);
  const [selectedNews, setSelectedNews] = useState<INews | null>(null);

  function handleChangeForm(val: string) {
    setActiveForm(val);
    window.history.replaceState(null, '', `?category=${val}`);
  }

  function newsToggle(selected : INews | null = null) {
    setSelectedNews(selected);
    modaleRef?.current?.toggle();
  }

  const getData = useCallback(() => {
    getToolsAction(dispatch);
    getToolsTypesAction(dispatch);
    getNewsAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    if (!toolsList && !user?.tools && newsList) return;
    const categoriesList = [];

    if (size.width && size.width < 1024) {
      const actu = {
        label: 'Actualités',
        type: 'news',
        newsList,
      };
      categoriesList.push(actu);
    }

    let list = toolsList?.filter(
      (t) => user?.favoriteTools?.find((f) => f === t._id),
    );
    let cat = {
      label: 'Mes favoris',
      type: 'favoris',
      list,
    };
    categoriesList.push(cat);

    list = toolsList?.filter((t) => t.type === 'Outils Octapharma au quotidien');
    cat = {
      label: 'Octapharma',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }

    list = toolsList?.filter((t) => t.type === 'Outils médicaux interne' || t.type === 'product');
    cat = {
      label: 'Outils',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }

    list = toolsList?.filter((t) => t.type === 'Outils de veille');
    cat = {
      label: 'Veille',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }

    list = toolsList?.filter((t) => t.type !== 'Outils Octapharma au quotidien'
        && t.type !== 'Outils médicaux interne'
        && t.type !== 'product'
        && t.type !== 'Outils de veille'
        && t.type !== 'media');
    cat = {
      label: 'Autres',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }

    list = toolsList?.filter((t) => t.type === 'media');
    cat = {
      label: 'Médias',
      type: 'tool',
      list,
    };
    if (list?.length) {
      categoriesList.push(cat);
    }
    setCategories(categoriesList);
  }, [toolsList, user, size, newsList]);

  useEffect(() => {
    if (news?.length) {
      const list = news?.filter((n) => n.status === 'published').sort((a: INews, b: INews) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const aDate : any = new Date(a.date);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const bDate : any = new Date(b.date);
        return bDate - aDate;
      });
      setNewsList(list);
    }
  }, [news]);

  useEffect(() => {
    if (error === '401') {
      logout(dispatch);
      navigate('/');
    }
  }, [error]);

  return (
    <>
      <div className={`${styles.container} ${notifsIsActive ? styles['is-notifs'] : ''}`}>
        <header>
          <div className={styles.logo}>
            <img src={logo} alt="logo" />
          </div>
          <User />
        </header>
        {categories && (
          <main>
            {user?.firstName && (
              <h1>
                Bonjour
                {` ${user?.firstName}`}
              </h1>
            )}
            <nav className={styles.nav}>
              <ul ref={navRef}>
                {categories?.map((l, i) => (
                  <li key={l.label}>
                    <button
                      type="button"
                      className={`${activeForm === i.toString() ? styles.active : ''}`}
                      onClick={() => handleChangeForm(i?.toString())}
                    >
                      {l.label}
                    </button>
                  </li>
                ))}
              </ul>
            </nav>
            <div className={styles.content}>
              <div className={styles.categories} style={{ transform: `translateX(-${(Number(activeForm) * 100) / categories.length}%)` }}>
                {categories.map((c) => <Category key={`category-${c.label}`} category={c} newsToggle={(n) => newsToggle(n)} />)}
              </div>
            </div>
          </main>
        )}
      </div>
      <div className={styles.actus}>
        {newsList.length > 0 && <h2>Actualités</h2>}
        <div className={styles['news-container']}>
          {newsList?.map((n) => (
            <div
              key={n._id}
              className={styles.news}
            >
              <button
                onClick={() => newsToggle(n)}
                className="bold"
                type="button"
              >
                {n.title}
              </button>
              <div className={styles.infos}>
                <p>{format(new Date(n.date), 'dd/MM/yyyy')}</p>
                <p>
                  {`${n?.author?.profile?.firstName} ${n?.author?.profile?.lastName}`}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <ModalStandalone
        hideCloseButton
        ref={modaleRef}
      >
        <div className={styles['news-modal']}>
          <button
            type="button"
            onClick={() => newsToggle()}
          >
            <RiCloseFill size={28} />
          </button>
          {selectedNews?.image?.path && (
            <img
              src={`${API_URL}/${selectedNews?.image?.path}`}
              alt="tool"
            />
          )}
          <div className={styles.content}>
            <h1>{selectedNews?.title}</h1>
            <div className={styles.infos}>
              <p>{selectedNews?.date && format(new Date(selectedNews.date), 'dd/MM/yyyy')}</p>
              <p>
                {`${selectedNews?.author?.profile?.firstName} ${selectedNews?.author?.profile?.lastName}`}
              </p>
            </div>
            <div dangerouslySetInnerHTML={{ __html: selectedNews?.texte || '' }} />
          </div>
        </div>
      </ModalStandalone>
    </>
  );
}

export default OctaList;
