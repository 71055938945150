// AUTH ACTIONS
export const AUTH_SIGNIN = 'AUTH_SIGNIN';
export const AUTH_GET_PROFILE = 'AUTH_GET_PROFILE';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_LOADING = 'AUTH_LOADING';
export const AUTH_RESET_ERROR = 'AUTH_RESET_ERROR';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_MESSAGE = 'AUTH_MESSAGE';

// USER ACTION
export const PUT_USER = 'PUT_USER';

// TOOLS ACTIONS
export const GET_TOOLS = 'GET_TOOLS';
export const TOOL_ERROR = 'TOOL_ERROR';
export const GET_TOOLS_TYPES = 'GET_TOOLS_TYPES';
export const GET_TOOL = 'GET_TOOL';
export const POST_TOOL_FORM = 'POST_TOOL_FORM';

// NEWS ACTIONS
export const GET_NEWS = 'GET_NEWS';

// NOTIFS ACTIONS
export const GET_NOTIFS = 'GET_NOTIFS';
export const SET_NOTIFS_IS_ACTIVE = 'SET_NOTIFS_IS_ACTIVE';
