import { getData } from './index';
import { DispatchType } from '../types';
import { AUTH_ERROR, GET_NOTIFS } from './actions';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNofifsAction = async (dispatch: DispatchType) : Promise<any> => {
  const url = '/notification/Octasmart';
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  if (response.data.notifications) {
    dispatch({
      type: GET_NOTIFS,
      payload: response.data.notifications,
    });
  }
};

export default getNofifsAction;
