/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios from 'axios';
import { DispatchType, AnyObject } from '../types';
import { AUTH_ERROR } from './actions';
import { API_URL } from '../constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function errorHandler(dispatch: DispatchType, error: any, type: string): void {
  // eslint-disable-next-line no-console
  console.log('Error type: ', type);
  if (type !== AUTH_ERROR && error.response !== undefined && error?.response?.status === 401) {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
    }
    // window.location.href = '/';
  }
  if (type) {
    dispatch({
      type,
      payload: error.response,
    });
  }
}

export async function postData(
  errorType: string,
  url: string,
  dispatch: DispatchType,
  data: any,
  isAuthReq: boolean,
): Promise<any> {
  const requestUrl = API_URL + url;
  const config: AnyObject = {};
  let res: any = null;
  let error: any = null;
  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.post(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res.status === 200 || res.status === 201 ? res : error;
}

export async function getData(
  errorType: string,
  url: string,
  dispatch: DispatchType,
  isAuthReq: boolean,
): Promise<any> {
  const requestUrl = API_URL + url;
  const config: AnyObject = {};
  let res: any = null;
  let error: any = null;
  if (isAuthReq) {
    const token = localStorage.getItem('token');
    config.headers = { Authorization: `${token}` };
  }
  try {
    res = await axios.get(requestUrl, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, err, errorType);
  }
  return res.status === 200 ? res : error;
}

export async function putData(
  errorType: string,
  url: string,
  dispatch: DispatchType,
  data: AnyObject,
  isAuthReq = true,
) {
  const requestUrl = API_URL + url;
  const config: AnyObject = {};
  let res: any = null;
  let error: any = null;
  try {
    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = { Authorization: `${token}` };
    }
    res = await axios.put(requestUrl, data, config);
  } catch (err) {
    error = err;
    errorHandler(dispatch, error, errorType);
  }
  return res.status === 200 ? res : error;
}

export function deleteData(
  errorType: string,
  url: string,
  dispatch: DispatchType,
  isAuthReq: boolean,
): Promise<any> {
  return new Promise((resolve, reject) => {
    const requestUrl = API_URL + url;

    const config: AnyObject = {};

    if (isAuthReq) {
      const token = localStorage.getItem('token');
      config.headers = {
        Authorization: `${token}`,
      };
    }

    axios.delete(requestUrl, config)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject();
        errorHandler(dispatch, error, errorType);
      });
  });
}
