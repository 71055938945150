import React, { useCallback, useEffect } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import { MdPlayArrow } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';

import { API_URL } from '../../constants';
import { getToolAction, sendToolRequestAction } from '../../actions/toolsActions';
import { ApplicationState } from '../../types';

import logo from '../../assets/images/logo-octasmart.svg';
import login from '../../assets/images/login.png';
import styles from './ContactForm.module.scss';
import { IToolContact } from '../../types/tools.types';
import { ErrorField, InputText } from '../../lib/HooksFormFields';
import { POST_TOOL_FORM } from '../../actions/actions';

function ContactForm(): JSX.Element {
  const { id } = useParams();
  const { notifsIsActive } = useSelector((state : ApplicationState) => state.authReducer);
  const { tool, message } = useSelector((state : ApplicationState) => state.toolsReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IToolContact>();

  function submitForm(values : IToolContact) {
    sendToolRequestAction(dispatch, { ...values, tool: tool?.title || '' });
  }

  const getData = useCallback(() => {
    getToolAction(dispatch, id);
    dispatch({
      type: POST_TOOL_FORM,
      payload: null,
    });
  }, [dispatch, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div className={`${styles.container} ${notifsIsActive ? styles['is-notifs'] : ''}`}>
      {tool?._id && (
        <>
          <header>
            <div className={styles.logo}>
              <img src={logo} alt="logo" />
            </div>
          </header>
          <div className={styles.row}>
            <div className={`${styles.col} ${styles.form}`}>
              <div>
                <div className={`${styles.illu} ${styles.mobile}`}>
                  {tool?.image
                    ? <img src={`${API_URL}/${tool?.image?.path}`} alt="tool" />
                    : <img src={login} alt="tool" />}
                </div>
                <h1>Demande de contact</h1>
                <button
                  type="button"
                  onClick={() => navigate(-1)}
                  className={styles.back}
                >
                  <HiArrowLeft />
                  Retour
                </button>
              </div>

              <form onSubmit={handleSubmit(submitForm)}>
                <div className={styles.name}>
                  <div className={styles['form-group']}>
                    <InputText
                      name="firstName"
                      control={control}
                      label="Nom"
                      rules={{ required: 'le nom est requis' }}
                      placeholder=""
                    />
                    {errors.firstName && <ErrorField message={errors.firstName.message} />}
                  </div>
                  <div className={styles['form-group']}>
                    <InputText
                      name="lastName"
                      control={control}
                      label="Prénom"
                      rules={{ required: 'le prénom est requis' }}
                      placeholder=""
                    />
                    {errors.lastName && <ErrorField message={errors.lastName.message} />}
                  </div>
                </div>
                <div className={styles['form-group']}>
                  <InputText
                    name="structure"
                    control={control}
                    label="Etablissement"
                    placeholder=""
                  />
                  {errors.structure && <ErrorField message={errors.structure.message} />}
                </div>
                <div className={styles['form-group']}>
                  <InputText
                    name="tel"
                    control={control}
                    rules={{ required: 'le numéro de téléphone est requis' }}
                    label="Numéro de téléphone"
                    placeholder=""
                  />
                  {errors.tel && <ErrorField message={errors.tel.message} />}
                </div>

                {message
                  ? (
                    <p className={styles.message}>{message}</p>
                  ) : (
                    <button
                      type="submit"
                      className={styles.submit}
                    >
                      <p>Envoyer ma demande</p>
                      <MdPlayArrow size={22} />
                    </button>
                  )}
              </form>

              <p className={styles.legal}>
                Les informations recueillies font l’objet d’un traitement
                informatique chez OCTAPHARMA France.
                Le responsable de ces données est Octapharma France.
                Ces informations seront conservées pendant
                une durée de 3 ans à compter du dernier contact.
                A l’issue de ce délai, elles seront rendues anonymes et conservées
                à des fins de statistique et d’amélioration du service.
                Conformément à la réglementation sur la protection des données personnelles,
                vous avez un droit d’accès, de rectification,
                d’opposition, d’effacement
                et de limitation du traitement des données vous concernant,
                pour exercer ces droits,
                vous devez adresser votre demande à
                <a href="mailto:FR2DPO@octapharma.com" className="bold"> FR2DPO@octapharma.com</a>
              </p>

            </div>
            <div className={styles.col}>
              <div className={`${styles.illu}`}>
                {tool?.image
                  ? <img src={`${API_URL}/${tool?.image?.path}`} alt="tool" />
                  : <img src={login} alt="tool" />}
              </div>
              <div className={styles.infos}>
                <div className={styles.logo}>
                  {tool?.icon
                    ? <img src={`${API_URL}/${tool?.icon?.path}`} alt="tool" />
                    : <img src={login} alt="tool" />}
                </div>
                <div className={styles.title}>
                  <h1>{tool?.title}</h1>
                  <p>{tool?.description}</p>
                  {tool.url && (
                    <a
                      href={tool.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>Voir l&apos;outil</span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ContactForm;
