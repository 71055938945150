import { getData, postData } from './index';
import { DispatchType } from '../types';
import {
  GET_TOOLS,
  GET_TOOL,
  GET_TOOLS_TYPES,
  TOOL_ERROR,
  POST_TOOL_FORM,
} from './actions';
import { IToolContact } from '../types/tools.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getToolsAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/tool';
  const response = await getData(TOOL_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: GET_TOOLS,
      payload: response.data,
    });
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getToolsTypesAction = async (dispatch: DispatchType): Promise<any> => {
  const url = '/tool/list/type';
  const response = await getData(TOOL_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: GET_TOOLS_TYPES,
      payload: response.data,
    });
  }
};

export const getToolAction = async (
  dispatch: DispatchType,
  id: string | undefined,
// eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const url = `/tool/${id}`;
  const response = await getData(TOOL_ERROR, url, dispatch, true);
  if (response.data) {
    dispatch({
      type: GET_TOOL,
      payload: response.data,
    });
  }
};

export const sendToolRequestAction = async (dispatch: DispatchType, data: IToolContact)
// eslint-disable-next-line @typescript-eslint/no-explicit-any
: Promise<any> => {
  const url = '/tool/request';
  const promise = await postData(TOOL_ERROR, url, dispatch, data, true)
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((response: any) => {
      if (response) {
        dispatch({
          type: POST_TOOL_FORM,
          payload: 'Votre demande a bien été prise en compte',
        });
      }
    });
  return promise;
};
