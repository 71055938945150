import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ITool } from '../../types/tools.types';
import { ApplicationState } from '../../types';

import login from '../../assets/images/login.png';
import pharma from '../../assets/images/pharma-icon.svg';
import styles from './Card.module.scss';
import { API_URL } from '../../constants';

function Card({ tool, type }: { tool : ITool, type: string | undefined }) : JSX.Element {
  const { userUrl, user } = useSelector((state : ApplicationState) => state.authReducer);
  const navigate = useNavigate();

  function redirectToTool() {
    const isActive = user?.tools?.find((t) => t.tool === tool._id);
    if (isActive && tool.url) {
      return window.open(tool.url, '_blank');
    }
    return navigate(`${userUrl}/${tool._id}`);
    // const isActive = user?.tools?.find((t) => t.tool === tool._id);
    // if (user?.type !== 'Octapharma') {
    //   if (isActive && tool.url) {
    //     return window.open(tool.url, '_blank');
    //   }
    //   return navigate(`${userUrl}/${tool._id}`);
    // }
    // if (!tool.url) {
    //   return navigate(`${userUrl}/${tool._id}`);
    // }
    // return window.open(tool.url, '_blank');
  }

  return (
    <button
      type="button"
      onClick={() => redirectToTool()}
      className={styles.container}
    >
      {tool?.image
        ? <img src={`${API_URL}/${tool.image.path}`} className={styles['tool-image']} alt="tool" />
        : <img src={login} className={styles['tool-image']} alt="tool" />}
      <div className={styles.tool}>
        <p>{tool?.subtype || type}</p>
        <h2>{tool.title}</h2>
      </div>
      <div className={styles.icon}>
        <img src={pharma} className={styles['tool-image']} alt="tool" />
      </div>
    </button>
  );
}

export default Card;
