import React from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../types';
import { ICategory } from '../../types/tools.types';
import Card from '../Card/Card';
import OctaTool from '../OctaTool/OctaTool';
import News from '../New/New';
import styles from './Category.module.scss';
import { INews } from '../../types/auth.types';

function Category(
  { category, newsToggle }
  :
  { category: ICategory, newsToggle:(news: INews) => void },
): JSX.Element {
  const { lists } = useSelector((state : ApplicationState) => state.toolsReducer);
  return (
    <div className={styles.container}>
      <h2>{category.label}</h2>
      <div className={styles[category.type]}>
        {category.type === 'favoris' && category.list?.map((t) => (
          <Card
            key={t._id}
            tool={t}
            type={lists.types?.find((type) => type.value === t.type)?.label}
          />
        ))}
        {category.type === 'tool' && category.list?.map((t) => (
          <OctaTool
            key={t._id}
            tool={t}
          />
        ))}
        {category.type === 'news' && category.newsList?.map((n) => (
          <News
            key={n._id}
            news={n}
            newsToggle={(news) => newsToggle(news)}
          />
        ))}
      </div>
    </div>
  );
}

export default Category;
