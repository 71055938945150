import React from 'react';
import { format } from 'date-fns';

import { API_URL } from '../../constants';

import { INotif } from '../../types/auth.types';

import login from '../../assets/images/login.png';
import styles from './Notifs.module.scss';

function Notifs(
  {
    notifs,
    isActive,
  } : {
    notifs: INotif[] | null,
    isActive : boolean,
  },
): JSX.Element {
  return (
    <div className={`${styles.container} ${isActive ? styles.active : ''}`}>
      <div className={styles.header} />
      <div className={styles.notifs}>
        {notifs?.map((n) => (
          <div key={n._id} className={styles.notif}>
            <div className={styles.logo}>
              {n.product?.icon?.path
                ? <img src={`${API_URL}/${n.product?.icon?.path}`} alt="tool" />
                : <img src={login} alt="tool" />}
            </div>
            <div className={styles.infos}>
              <h3>{n.title}</h3>
              <p>{n.description}</p>
            </div>
            <div className={styles.date}>
              <p>{format(new Date(n.updatedAt), 'dd/MM/yyyy')}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Notifs;
