import React, { useEffect, useCallback } from 'react';
import { BsFillBellFill } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout, notifsToggleAction } from '../../actions/authActions';
import styles from './User.module.scss';
import { ApplicationState } from '../../types';
import getNofifsAction from '../../actions/notifsActions';
import Notifs from '../Notifs/Notifs';

function User() {
  const {
    user, notifs, notifsIsActive,
  } = useSelector((state : ApplicationState) => state.authReducer);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const foundNews = !!notifs?.find((n) => n.new);

  const getData = useCallback(() => {
    getNofifsAction(dispatch);
  }, [dispatch]);

  function logoutUser() {
    logout(dispatch);
    navigate('/');
  }

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <div className={styles.user}>
        <button
          className={`${styles.notif} ${foundNews ? styles.new : ''}`}
          onClick={() => notifsToggleAction(dispatch)}
          type="button"
        >
          <BsFillBellFill />
        </button>
        <p>
          {user?.firstName}
          {` ${user?.lastName[0]}.`}
        </p>
        <button
          className={styles.logout}
          onClick={() => logoutUser()}
          type="button"
        >
          <span>
            {user?.firstName?.length && user?.firstName[0]}
            .
            {user?.lastName?.length && user?.lastName[0]}
          </span>
        </button>
      </div>
      <Notifs notifs={notifs} isActive={notifsIsActive} />
    </>
  );
}

export default User;
