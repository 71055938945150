import { getData } from './index';
import { DispatchType } from '../types';
import { AUTH_ERROR, GET_NEWS } from './actions';
import { INews } from '../types/auth.types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getNewsAction = async (dispatch: DispatchType) : Promise<any> => {
  const url = '/news';
  const response = await getData(AUTH_ERROR, url, dispatch, true);
  const news = response.data.news.filter((n: INews) => {
    const today = new Date();
    const lastMonth = new Date(today.setMonth(today.getMonth() - 1));
    if (n.date && new Date(n.date) > lastMonth) {
      return n;
    }
    return null;
  });
  if (news) {
    dispatch({
      type: GET_NEWS,
      payload: news,
    });
  }
};

export default getNewsAction;
